export default () => {
	const $cursor = document.querySelector('.js-cursor');
	const $cursorScale = document.querySelectorAll('.js-cursor-scale');

	if (!$cursor) return;

	$cursorScale.forEach(($el) => {
		$el.addEventListener('mouseleave', () => {
			$cursor.classList.remove('cursor--shown');
		});

		$el.addEventListener('mousemove', (e) => {
			$cursor.classList.add('cursor--shown');

			$cursor.style.left = e.clientX + 'px';
			$cursor.style.top = e.clientY + 'px';
		});
	});
};
