import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';

export default () => {
	const $cursor = document.querySelector('.js-cursor');

	new Swiper('.home-main-swiper', {
		modules: [Pagination, Autoplay],
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		scrollbar: {
			el: '.swiper-scrollbar',
			draggable: true,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			renderBullet(index, className) {
				return (
					'<span class="' + className + '">' + '0' + (index + 1) + '</span>'
				);
			},
		},
	});

	// cards swiper
	const containers = document.querySelectorAll('.js-cards-container');
	if (!containers.length) return;

	containers.forEach((container) => {
		new Swiper(container.querySelector('.js-cards-slider'), {
			modules: [Scrollbar],
			slidesPerView: 1.2,
			spaceBetween: 8,
			breakpoints: {
				768: {
					slidesPerView: 2.05,
					spaceBetween: 20,
				},
				1055: {
					slidesPerView: 2.5,
					spaceBetween: 20,
				},
				1440: {
					slidesPerView: 'auto',
					spaceBetween: 20,
				},
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
			},
			on: {
				touchStart() {
					if ($cursor) {
						$cursor.classList.add('cursor--active');
					}
				},
				touchMove(swiper, e) {
					if ($cursor) {
						$cursor.style.left = e.clientX + 'px';
						$cursor.style.top = e.clientY + 'px';
					}
				},
				touchEnd() {
					if ($cursor) {
						$cursor.classList.remove('cursor--active');
					}
				},
			},
		});
	});

	new Swiper('.js-centered-carousel', {
		modules: [Navigation],
		slidesPerView: 'auto',
		centeredSlides: true,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			320: {
				spaceBetween: 12,
			},
			1056: {
				spaceBetween: 20,
			},
			1100: {
				spaceBetween: 60,
			},
			1300: {
				spaceBetween: 100,
			},
		},
	});
};
