export default () => {
	const $btn = document.querySelector('.home-hero__more');
	const $target = document.querySelector('#about');

	if (!$btn || !$target) return;

	$btn.addEventListener('click', () => {
		$target.scrollIntoView({
			behavior: 'smooth',
		});
	});
};
