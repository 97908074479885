import { enablePageScroll } from 'scroll-lock';

export default () => {
	const $menuInner = document.querySelector('.js-menu-nav-inner');
	const $menu = document.querySelector('.js-main-nav');
	const $header = document.querySelector('.js-header');
	const $mainMenuInner = document.querySelector('.js-main-nav__inner');

	enablePageScroll($menuInner);
	enablePageScroll($mainMenuInner);

	if ($header) {
		$header.classList.remove('header--main-nav-opened');
	}

	if ($menu) {
		$menu.classList.remove('main-nav--opened');
	}
};
