import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default () => {
	const $menuBtn = document.querySelector('.js-menu-nav-btn');
	const $menuInner = document.querySelector('.js-menu-nav-inner');
	const $menu = document.querySelector('.js-menu-nav-wrapp');

	if (!$menuBtn || !$menuInner || !$menu) return;

	$menuBtn.addEventListener('click', () => {
		$menu.classList.toggle('menu-nav-wrapp--opened');

		let isMenuOpened = $menu.classList.contains('menu-nav-wrapp--opened');

		if (isMenuOpened) {
			disablePageScroll($menuInner);
		} else {
			enablePageScroll($menuInner);
		}
	});

	const $links = document.querySelectorAll('.menu-nav__list a');
	const $anchorLinks = [];

	$links.forEach(($link) => {
		let hrefId = null;
		let $target = null;
		const hrefAttr = $link.getAttribute('href');
		const hrefStartIndex = $link.getAttribute('href').indexOf('#');

		if (hrefStartIndex !== -1) {
			hrefId = hrefAttr.slice(hrefStartIndex);
		}

		if (hrefId && hrefId !== '#') {
			$target = document.querySelector(hrefId);

			if (hrefId.length > 1 && $target) {
				$link.addEventListener('click', (e) => {
					e.preventDefault();

					$menu.classList.remove('menu-nav-wrapp--opened');
					enablePageScroll($menuInner);

					$target.scrollIntoView({
						behavior: 'smooth',
					});
				});
			}
		}
	});

	$links.forEach(($link) => {
		const hrefAttr = $link.getAttribute('href');
		if (hrefAttr.startsWith('#') && hrefAttr.length > 1) {
			$anchorLinks.push($link);
		}
	});

	const options = {
		threshold: 0,
		rootMargin: '-50% 0px -50% 0px',
	};

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			const $anchorEl = entry.target.querySelector('.anchor-link');
			const $activeLink = $anchorLinks.find(($link) =>
				$link.getAttribute('href').includes($anchorEl.id),
			);

			if (entry.isIntersecting) {
				$activeLink.parentElement.classList.add('active');
			} else {
				$activeLink.parentElement.classList.remove('active');
			}
		});
	}, options);

	$anchorLinks.forEach(($link) => {
		const $target = document.querySelector($link.getAttribute('href'));

		if ($target) {
			observer.observe($target.parentElement);
		}
	});
};
