import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { debounce } from '../helpers/debounce.js';

export default () => {
	const $header = document.querySelector('.js-header');
	const $burger = document.querySelector('.js-burger');
	const $menu = document.querySelector('.js-main-nav');
	const $menuInner = document.querySelector('.js-main-nav__inner');
	let isOpen = false;

	if ($burger && $header && $menu && $menuInner) {
		$burger.addEventListener('click', () => {
			isOpen ? closeMenu() : openMenu();
		});

		window.addEventListener('resize', debounce(closeMenuHandler, 300));
	}

	// anchor links
	if ($menuInner) {
		const $links = $menuInner.querySelectorAll('a');
		const $anchorLinks = [];

		$links.forEach(($link) => {
			let hrefId = null;
			let $target = null;
			const hrefAttr = $link.getAttribute('href');
			const hrefStartIndex = $link.getAttribute('href').indexOf('#');

			if (hrefStartIndex !== -1) {
				hrefId = hrefAttr.slice(hrefStartIndex);
			}

			if (hrefId && hrefId !== '#') {
				$target = document.querySelector(hrefId);

				if (hrefId.length > 1 && $target) {
					$link.addEventListener('click', (e) => {
						e.preventDefault();

						$menu.classList.remove('menu-nav-wrapp--opened');
						enablePageScroll($menuInner);

						closeMenu();

						$target.scrollIntoView({
							behavior: 'smooth',
						});
					});
				}
			}
		});

		$links.forEach(($link) => {
			const hrefAttr = $link.getAttribute('href');
			if (hrefAttr.startsWith('#') && hrefAttr.length > 1) {
				$anchorLinks.push($link);
			}
		});
	}

	function openMenu() {
		disablePageScroll($menuInner);
		$header.classList.add('header--main-nav-opened');
		$menu.classList.add('main-nav--opened');
		isOpen = true;
	}

	function closeMenu() {
		enablePageScroll($menuInner);
		$header.classList.remove('header--main-nav-opened');
		$menu.classList.remove('main-nav--opened');
		isOpen = false;
	}

	function closeMenuHandler() {
		if (window.innerWidth > 1054) {
			closeMenu();
		}
	}

	// header sticky
	setHeaderFixed();
	window.addEventListener('scroll', setHeaderFixed);

	function setHeaderFixed() {
		if (window.pageYOffset > 100 && $header) {
			$header.classList.add('header--scrolled');
		} else {
			$header.classList.remove('header--scrolled');
		}
	}
};
