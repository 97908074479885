export default () => {
	setMenuFixed();
	window.addEventListener('scroll', setMenuFixed);

	function setMenuFixed() {
		const $header = document.querySelector('.js-header');
		const $menu = document.querySelector('.js-menu-nav-wrapp');

		if (!$header || !$menu) return;

		if (window.pageYOffset > $menu.offsetTop - $header.offsetHeight) {
			$menu.classList.add('menu-nav-wrapp--scrolled');
		} else {
			$menu.classList.remove('menu-nav-wrapp--scrolled');
		}
	}
};
